<template>
    <CModal size="lg" :visible="show" :keyboard="false" @show="handleShow" backdrop="static" @close="handleClose">
        <CModalHeader :close-button="false">
            Title
            <CButton
                v-c-tooltip="{ content: 'Set your important notes, as favorites for faster navigation in the notes list.', placement: 'left' }"
                variant="ghost" @click="form.is_favorite = !form.is_favorite">
                <CIcon size="xl" name="cilStar" :style="{ color: starColor }"/>
            </CButton>
        </CModalHeader>
        <CModalBody>
            <CRow>
                <CCol xs="12">
                    <CFormInput v-model="form.title" :invalid="!!validationErrors.title" placeholder="Title"
                                @blur="handleChangeField('title')"/>
                    <CFormText v-if="validationErrors.title" style="color: red">
                        {{ validationErrors.title }}
                    </CFormText>
                </CCol>

                <CCol xs="12" class="mt-4">
                    <CFormLabel>Note text</CFormLabel>
                    <CFormTextarea ref="noteText" v-model="form.text" style="height: 200px; resize: none"
                                   :invalid="!!validationErrors.text" @blur="handleChangeField('text')"/>
                    <CFormText v-if="validationErrors.text" style="color: red">
                        {{ validationErrors.text }}
                    </CFormText>
                </CCol>
                <CCol v-if="note.id" xs="12" class="mt-3">
                    <p class="lastEdit" style="text-align: right">
                        Last edit {{ note.last_edit }} by
                        {{ note.editor_name }}
                    </p>
                </CCol>
            </CRow>
            <ConfirmDialog ref="confirmDialog"/>
        </CModalBody>
        <CModalFooter>
            <CRow class="w-100">
                <CCol>
                    <div style="text-align: right">
                        <CButton variant="ghost" class="mx-3" @click="handleClose">
                            Cancel
                        </CButton>
                        <CButton color="primary" :disabled="loading" @click="save">
                            <CSpinner v-if="loading" component="span" size="sm"/>
                            Save
                        </CButton>
                    </div>
                </CCol>
            </CRow>
        </CModalFooter>
    </CModal>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import {parseError} from '@/utils/api'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import {equalObjectsByKeys} from '@/utils/helper'

export default {
    name: 'FormDialog',
    components: {ConfirmDialog},
    inject: ['toast'],
    props: {
        note: {
            type: Object, default: () => {
            }
        },
        show: {type: Boolean, default: () => false},
    },
    emits: ['update:show', 'update'],
    data() {
        return {
            origin: {},
            form: {
                title: '',
                text: '',
                is_favorite: false,
            },
            loading: false,
            validator: {},
            validationErrors: {},
        }
    },
    computed: {
        starColor() {
            return this.form.is_favorite ? 'orange' : '#303c54'
        },
    },
    mounted() {
        this.$nextTick(() => this.setRules())
    },
    methods: {
        handleShow() {
            this.validationErrors = {}
            if (Object.keys(this.note).length > 0) {
                this.form = JSON.parse(JSON.stringify(this.note))
            }
            this.origin = JSON.parse(JSON.stringify(this.form))
        },
        async handleClose() {
            if (equalObjectsByKeys(this.form, this.origin, Object.keys(this.form))) {
                this.close()
            } else {
                await this.confirmClose()
            }
        },
        close() {
            this.$emit('update:show', false)

            for (let key in this.form) {
                this.form[key] = undefined
            }
        },
        async confirmClose() {
            await this.$refs.confirmDialog
                .confirm({
                    text: 'Are you sure you want to close this note?',
                    info: '<p>Сhanges will not be saved</p>',
                    confirmText: 'Back to editing',
                    cancelText: 'Close',
                    reverseState: true,
                })
                .then((res) => {
                    if (res) {
                        this.close()
                    }
                })
        },
        setRules() {
            this.validator = validator({
                title: [rules.required, rules.strMax(255)],
                text: [rules.required, rules.strMax(2000)],
            })
        },
        handleChangeField(field) {
            this.validationErrors[field] = this.validator.validate(
                field,
                this.form[field],
            )
        },
        save() {
            const validationResult = this.validator.validateAll(this.form)
            if (!validationResult.hasErrors) {
                this.loading = true
                this.form['company_id'] = this.$route.params.id
                const action = this.note.id
                    ? this.$http.companies.updateNote(this.note.id, this.form)
                    : this.$http.companies.createNote(this.form)
                action
                    .then((response) => {
                        this.$emit('update')
                        this.close()
                        this.toast('info', response.data.message)
                    })
                    .catch((err) => {
                        err = parseError(err)
                        if (err.status === 422) {
                            this.validationErrors = err.validationMessages
                        } else {
                            this.toast('warning', err.message)
                        }
                    })
                    .finally(() => (this.loading = false))
            } else {
                this.validationErrors = validationResult.validationErrors
            }
        },
    },
}
</script>

<style scoped></style>
