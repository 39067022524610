import MasonryWall from '@yeger/vue-masonry-wall'
import FormDialog from './components/FormDialog'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import AccordionItem from '@/components/Page/AccordionItem'
import TextBlock from './components/TextBlock'
import Gate from '@/services/Gate/Gate'
import {notifyByPlan} from '@/services/Notify/Toasts'

export default {
    components: {
        MasonryWall,
        FormDialog,
        ConfirmDialog,
        AccordionItem,
        TextBlock,
    },
    inject: ['toast', 'mountedComponent'],
    created() {
        this.$nextTick(async () => {
            await Gate.can('actions', 'note', this.$route.params.id)
                .then((res) => this.hasActions = res)
            this.fetchData()
            if (this.$refs.note && this.$refs.note.$el) {
                this.$refs.note.$el.maxLength = 2000
            }
        })
    },
    data() {
        return {
            items: [],
            hasActions: false,
            loading: false,
            showDialog: false,
            selectedNote: {},
            sort: {direction: 'desc', field: 'created_at'},
            filterOptions: [
                {
                    label: 'Latest Notes',
                    value: '0',
                    field: 'created_at',
                    direction: 'desc',
                },
                {
                    label: 'Oldest Notes',
                    value: '1',
                    field: 'created_at',
                    direction: 'asc',
                },
                {
                    label: 'Favorites Notes',
                    value: '2',
                    field: 'is_favorite',
                    direction: 'desc',
                },
            ],
        }
    },
    methods: {
        lastEditOfNote(date) {
            return this.$TCurrentZone(date, true).toFormat('dd/LL/yyyy HH:mm')
        },
        filterChange(val) {
            let index = val.target.value
            let item = this.filterOptions[index]
            this.sort = {direction: item.direction, field: item.field}
            this.fetchData()
        },
        async handleShowDialog(note = {}) {
            if (!this.hasActions) {
                notifyByPlan()
                return false
            }
            this.selectedNote = note
            this.showDialog = true
        },
        fetchData() {
            this.loading = true
            this.$http.companies
                .getNotes({
                    params: {
                        company_id: this.$route.params.id,
                        sort_direction: this.sort.direction,
                        sort_field: this.sort.field,
                    },
                })
                .then((res) => {
                    this.items = res.data.data
                })
                .catch(({response}) => this.toast('warning', response.data.message))
                .finally(() => (this.loading = false))
        },
        handleEdit(note) {
            if (!this.hasActions) {
                notifyByPlan()
                return false
            }
            this.selectedNote = note
            this.showDialog = true
        },
        delete(id) {
            this.$http.companies
                .deleteNote(id)
                .then((response) => {
                    this.toast('info', response.data.message)
                    this.fetchData()
                })
                .catch((err) => this.toast('warning', err.response.data.message))
        },
        async handleDelete(id) {
            if (!this.hasActions) {
                notifyByPlan()
                return false
            }
            await this.$refs.confirmDialog
                .confirm({
                    text: 'Are you sure you want to delete this note?',
                    cancelText: 'Cancel',
                    confirmText: 'Delete',
                    reverse: true,
                })
                .then((res) => {
                    if (res) this.delete(id)
                })
        },
    },
}
